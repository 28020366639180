import Head from 'next/head';
import Layout from '../components/Layout';

import { Grid, GridItem, Container, Heading, Text } from '@terminal/design-system';
import { PrimaryButton } from '../components/UI/Button';
import Image404 from '../components/UI/Image404';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { JobOpenings404Page } from '@_pages/engineering/job-openings/components';

export default function Custom404() {
  const router = useRouter();

  if (router.asPath.includes('engineers/job-openings')) {
    return <JobOpenings404Page />;
  }

  return (
    <>
      <Head>
        <title>404: This page could not be found</title>
        <meta name="robots" content="noindex, nofollow" />
      </Head>
      <Layout type="businesses" withCTA={false}>
        <Container py={{ base: '14', lg: '24' }}>
          <Grid
            gridTemplateColumns={{ lg: '1fr 1fr' }}
            gap={{ base: '14', lg: '10', xl: '14' }}
            maxW={{ base: '30rem', lg: '90%' }}
            mx="auto"
          >
            <GridItem order={{ lg: 1 }}>
              <Image404 />
            </GridItem>
            <GridItem>
              <Heading
                as="h1"
                fontSize={{ base: '4.5rem' }}
                lineHeight={{ base: '4.5rem' }}
                fontWeight="700"
              >
                404 <br />
                <Text fontSize={{ base: '2.625rem' }} lineHeight={{ base: '3rem' }}>
                  Ooops! Page Not Found
                </Text>
              </Heading>
              <Text mt="4" fontSize="lg" lineHeight="1.5rem">
                This page doesn’t exist or was removed! We suggest you back to home
              </Text>
              <PrimaryButton href="/" mt="6">
                Back to Home
              </PrimaryButton>
            </GridItem>
          </Grid>
        </Container>
      </Layout>
    </>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['header', 'footer'])),
    },
  };
}
